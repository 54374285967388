// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
}
:root {
  --color-text: #2e3842;
  --color-white: #fff;
  --color-primary: #2887c7;
  --color-secondary: #ca0210;
  --color-error: #ca0210;
  --color-warning: #ffca28;
  --color-info: #2887c7;
  --color-success: #29cc00;
  --color-odd: #f9fafb;
  --color-even: #edf0f2;
}



img {
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  /* width: 5px; */
  /* height: 3px; */
}
::-webkit-scrollbar-track {
  background: var(--color-primary);
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
`, "",{"version":3,"sources":["webpack://./src/style/css-variable.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;AACA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,wBAAwB;EACxB,0BAA0B;EAC1B,sBAAsB;EACtB,wBAAwB;EACxB,qBAAqB;EACrB,wBAAwB;EACxB,oBAAoB;EACpB,qBAAqB;AACvB;;;;AAIA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,gCAAgC;AAClC;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB","sourcesContent":["* {\n  box-sizing: border-box;\n}\n:root {\n  --color-text: #2e3842;\n  --color-white: #fff;\n  --color-primary: #2887c7;\n  --color-secondary: #ca0210;\n  --color-error: #ca0210;\n  --color-warning: #ffca28;\n  --color-info: #2887c7;\n  --color-success: #29cc00;\n  --color-odd: #f9fafb;\n  --color-even: #edf0f2;\n}\n\n\n\nimg {\n  width: 100%;\n  height: 100%;\n}\n\n::-webkit-scrollbar {\n  /* width: 5px; */\n  /* height: 3px; */\n}\n::-webkit-scrollbar-track {\n  background: var(--color-primary);\n}\n::-webkit-scrollbar-thumb {\n  background: #888;\n}\n::-webkit-scrollbar-thumb:hover {\n  background: #555;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
